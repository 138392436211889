import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import cx from 'clsx';

import { Theme } from 'aspects/theme';
import { GetQALocator, QALocatorProp } from 'aspects/qaLocators';
import CloseIcon from 'shared/assets/icons/close-icon.svg?react';

import styles from './NewModal.module.scss';
import { Modal } from '../Modal';

type Props = PropsWithChildren<
  {
    icon?: JSX.Element;
    title?: string;
    isOpen: boolean;
    theme?: Theme;
    headerContent?: JSX.Element;
    footer?: JSX.Element;
    containerClass?: string;
    contentClass?: string;
    withTransparentOverlay?: boolean;
    parentSelector?: () => HTMLElement;
    onClose?(): void;
    getQALocator?: GetQALocator;
  } & Partial<BottomButtonsProps>
>;

export function NewModal(props: Props) {
  const {
    isOpen,
    onClose,
    icon,
    title,
    children,
    theme = 'light',
    buttons,
    headerContent,
    footer,
    containerClass,
    contentClass,
    withTransparentOverlay,
    getQALocator,
    parentSelector,
  } = props;

  return (
    <Modal.Overlay
      isOpen={isOpen}
      onClose={onClose}
      theme={theme}
      isTransparent={withTransparentOverlay}
      reactModalProps={
        parentSelector && {
          parentSelector,
        }
      }
    >
      <NewModal.Container
        icon={icon}
        title={title}
        buttons={buttons}
        headerContent={headerContent}
        footer={footer}
        containerClass={containerClass}
        contentClass={contentClass}
        theme={theme}
        onClose={onClose}
        getQALocator={getQALocator}
      >
        {children}
      </NewModal.Container>
    </Modal.Overlay>
  );
}

type ContainerProps = PropsWithChildren<
  {
    icon?: JSX.Element;
    title?: string;
    headerContent?: JSX.Element;
    footer?: JSX.Element;
    containerClass?: string;
    contentClass?: string;
    theme?: Theme;
    onClose?(): void;
    getQALocator?: GetQALocator;
  } & Partial<BottomButtonsProps>
>;

NewModal.Container = function NewModalContainer(props: ContainerProps) {
  const {
    icon,
    title,
    children,
    buttons,
    headerContent,
    footer,
    containerClass,
    contentClass,
    getQALocator,
    theme = 'light',
    onClose,
  } = props;

  return (
    <div
      className={cx(
        styles.container,
        containerClass,
        styles[theme],
        theme === 'light' && styles.withGradientHeaderLine,
      )}
      {...getQALocator?.('container')}
    >
      {onClose && (
        <button
          type="button"
          className={styles.closeButton}
          onClick={onClose}
          {...getQALocator?.('close-button')}
        >
          <CloseIcon />
        </button>
      )}
      <header
        className={cx(styles.header, styles[theme])}
        {...getQALocator?.('header')}
      >
        {icon && <div className={styles.containerIcon}>{icon}</div>}
        {title && <h3 className={styles.containerTitle}>{title}</h3>}
        {headerContent}
      </header>
      <div
        className={cx(styles.content, contentClass)}
        {...getQALocator?.('content')}
      >
        {children}
      </div>
      {footer}
      {buttons && (
        <NewModal.BottomButtons
          buttons={buttons}
          color={theme === 'dark' ? 'purple' : 'gradient'}
        />
      )}
    </div>
  );
};

export type NewModalTextPart = { text: string; isBold?: boolean };

// text is Array to allow having bold parts when it's needed
type TextProps = {
  textParts: NewModalTextPart[];
  align?: 'left' | 'center';
  className?: string;
};

NewModal.Text = function Text(props: TextProps) {
  const { className, textParts, align = 'center' } = props;
  return (
    <p className={cx(styles.text, className, styles[align])}>
      {textParts.map(({ text, isBold }, i) => (
        <span key={i} className={cx(styles.textPart, isBold && styles.bold)}>
          {text}
        </span>
      ))}
    </p>
  );
};

export type NewModalButton = {
  text: string;
  className?: string;
  ref?: React.LegacyRef<HTMLButtonElement>;
  form?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  isLoading?: boolean;
  isDisabled?: boolean;
  qaLocatorProp?: QALocatorProp;
  onClick?: () => void;
};

type BottomButtonsProps = {
  buttons: NewModalButton[];
  color?: 'gradient' | 'purple';
};

NewModal.BottomButtons = function BottomButtons(props: BottomButtonsProps) {
  const { buttons, color = 'gradient' } = props;

  return (
    <div className={cx(styles.buttons, color && styles[color])}>
      {buttons.map(
        (
          {
            text,
            className,
            ref,
            isLoading,
            isDisabled,
            qaLocatorProp,
            ...rest
          },
          i,
        ) => (
          <button
            key={i}
            type="button"
            className={cx(
              styles.button,
              styles[color],
              isLoading && styles.loading,
              buttons.length === 1 && styles.single,
              className,
            )}
            ref={ref}
            disabled={isDisabled || isLoading}
            {...qaLocatorProp}
            {...rest}
          >
            {text}
          </button>
        ),
      )}
    </div>
  );
};
