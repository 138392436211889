import Tippy, { TippyProps } from '@tippyjs/react';
import cx from 'clsx';
import { useState } from 'react';

import 'tippy.js/animations/shift-away-subtle.css';
import InfoIcon from 'shared/assets/icons/circle-info.svg?react';
import { makeGetQALocator } from 'aspects/qaLocators';
import { ClassesProp } from 'shared/types';

import styles from './HelpText.module.scss';

type Props = {
  text: React.ReactNode;
  tooltipTheme: TooltipTheme;
  maxWidth?: number;
  tooltipOffsetY?: number;
  tooltipPlacement?: TooltipProps['placement'];
  iconClassName?: string;
  label?: string;
} & Omit<TippyProps, 'placement' | 'content' | 'theme'>;

const getQALocator = makeGetQALocator('help-text');

export function HelpText(props: Props) {
  const {
    text,
    tooltipTheme,
    tooltipOffsetY,
    maxWidth = 132,
    tooltipPlacement = 'top',
    iconClassName,
    label,
    ...rest
  } = props;

  return (
    <Tooltip
      placement={tooltipPlacement}
      content={
        typeof text === 'string' ? (
          <p {...getQALocator('text')}>{text}</p>
        ) : (
          text
        )
      }
      maxWidth={maxWidth}
      offset={[0, tooltipOffsetY ?? 0]}
      appendTo={document.body}
      theme={tooltipTheme}
      {...rest}
    >
      <div className={styles.helpText}>
        {label && <span className={styles.label}>{label}</span>}
        <InfoIcon
          className={cx(styles.infoIcon, iconClassName)}
          {...getQALocator('icon')}
        />
      </div>
    </Tooltip>
  );
}

export type TooltipTheme =
  | 'purple'
  | 'gray-light'
  | 'gray-middle'
  | 'gray-dark'
  | 'gray-transparent';

type TooltipProps = {
  content: React.ReactNode;
  theme?: TooltipTheme;
  placement?: 'top' | 'bottom' | 'left' | 'right';
} & Omit<TippyProps, 'placement' | 'content'> &
  ClassesProp<'wrapper'>;

export function Tooltip(props: TooltipProps) {
  // customize tooltip arrow https://popper.js.org/docs/v2/tutorial/#arrow
  const [triangle, setTriangle] = useState<HTMLDivElement | null>(null);

  const {
    theme = 'gray-light',
    children,
    content,
    classes,
    className,
    popperOptions,
    ...rest
  } = props;

  return (
    <div className={cx(styles.tooltipElWrapper, classes?.wrapper)}>
      <Tippy
        content={
          <div>
            {content}
            <div ref={setTriangle} className={styles.triangle} />
          </div>
        }
        className={cx(styles.tooltip, styles[theme], className)}
        animation="shift-away-subtle"
        popperOptions={{
          modifiers: [
            {
              name: 'arrow',
              options: {
                element: triangle,
              },
            },
            ...(popperOptions?.modifiers ?? []),
          ],
        }}
        interactive
        {...rest}
      >
        {children}
      </Tippy>
    </div>
  );
}
