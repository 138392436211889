import { GraphCacheConfig } from 'serverCache/schema.types';

export const KEYS: GraphCacheConfig['keys'] = {
  // https://formidable.com/open-source/urql/docs/graphcache/normalized-caching/#custom-keys-and-non-keyable-entities
  AdvancedFiltersSettings: (x) => x.__typename,
  AdvancedProductFilterOptions: () => null,
  ArtworkSetFilter: () => null,
  ArtworkSetListModel: () => null,
  BasketGroupModel: () => null,
  BasketItemStatusFlags: () => null,
  BasketModel: (x) => x.__typename,
  BaseMeasurementFilter: () => null,
  BudgetListModel: () => null,
  CompatibleProductSearchResult: () => null,
  ConfigurationModel: () => null,
  ContentKitsModel: () => null,
  CurrentUserModel: () => null,
  DimensionFilter: () => null,
  Dimensions: () => null,
  EditorCombinationDetailsModel: (x) => x.hashKey as string,
  EditorInitialValuesModel: () => null,
  FileInfo: () => null,
  FilterOptions: () => null,
  FinancialReportModel: () => null,
  KeyValuePairOfStringAndProductSelectionModel: () => null,
  KeyValuePairOfStringAndString: () => null,
  MyOrderListModel: () => null,
  OutputCombinationGroupingInfoModel: () => null,
  OutputCombinationListModel: () => null,
  OutputCombinationOrderInfo: () => null,
  PreviewsListModel: () => null,
  PremiumOrderingModel: () => null,
  PreviewStatsModel: () => null,
  ProductCompatibilityResult: () => null,
  ProductFilter: () => null,
  ProductListModel: () => null,
  ProductSelectionModel: () => null,
  RangeFilter: () => null,
  ReportRowModel: () => null,
  SearchFilterOptions: () => null,
  SearchResultSummary: () => null,
  ShapeCollectionFilter: () => null,
  ShapeCollectionListModel: () => null,
  ShapeViewerDataModel: () => null,
  TemplateFileResource: () => null,
  TemplateInputModel: () => null,
  TemplateInputOptions: () => null,
  TemplateInputRange: () => null,
  TemplateListModel: () => null,
  TemplateMetadata: () => null,
  TemplateOutput: () => null,
  TransactionModel: () => null,
  TransactionReportModel: () => null,
  UnitSize: () => null,
  UserProfileModel: () => null,
  UserProvisioningTableItemModel: () => null,
  Validator: () => null,
};
